import React from "react"
import styled from "styled-components"
import { AiOutlineMail } from "@react-icons/all-files/ai/AiOutlineMail"
import { callApi } from "../../utils/auth"
import { navigate } from "gatsby"
import TextField from "@mui/material/TextField"
import InputAdornment from "@mui/material/InputAdornment"
import { StaticImage } from "gatsby-plugin-image"

function ForgotPassword() {
  const [email, setEmail] = React.useState("")
  const [errorMsg, setErrorMsg] = React.useState("")
  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const response = await callApi("/auth/forgot-password", "POST", {
        email: email,
      })
      if (response.ok) {
        navigate("/email-confirm")
      } else {
        setErrorMsg(response.message[0].messages[0].message)
      }
    } catch (err) {
      setErrorMsg(err)
    }
  }
  return (
    <Wrapper>
      <div className="main_wrapper">
        <div className="forgot_password_image">
          {/* <StaticImage
            src="../images/forgotpass.png"
            alt="certisured forgot password"
            // placeholder="tracedSVG"
          /> */}
        </div>
        <h5>
          Enter your email address below and we'll send you a link to reset your
          password.
        </h5>
        {errorMsg ? <h6>{errorMsg}</h6> : null}
        <div className="search__container">
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="email"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            InputLabelProps={{
              style: {
                color: "#151515",
                opacity: "0.8",
                fontFamily: "var(--family)",
                fontWeight: "500",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <AiOutlineMail className="mail_icon" />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="button_wrapper">
          <button onClick={handleSubmit}>Reset Password</button>
        </div>
      </div>
    </Wrapper>
  )
}

export default ForgotPassword

const Wrapper = styled.div`
  display: flex;
  min-height: 600px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .mail_icon {
    font-size: 22px;
    color: #000;
  }
  .MuiOutlinedInput-root {
    font-family: var(--family) !important;
    padding-right: 0;
  }
  .MuiInputAdornment-root {
    width: 50px;
    justify-content: center;
  }
  h5 {
    margin: 0;
    font-weight: 500;
    font-size: 1.2rem;
    color: #000;
    text-align: center;
    @media (max-width: 767px) {
      font-size: 1rem;
    }
  }
  h6 {
    margin: 1rem 0;
    font-weight: 500;
    font-size: 1rem;
    color: red;
    text-align: center;
  }
  .main_wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;
    @media (max-width: 767px) {
      width: 90%;
    }
    .forgot_password_image {
      display: flex;
      justify-content: center;
      align-items: center;

      .gatsby-image-wrapper {
        width: 70%;
        @media (max-width: 767px) {
          width: 70%;
        }
      }
    }
  }
  .button_wrapper {
    button {
      margin-top: 1rem;
      background: var(--thirdColor);
      border-radius: 50px;
      color: var(--secondaryColor);
      font-weight: 500;
      font-size: 18px;
      display: flex;
      justify-content: center;
      min-width: 150px;
      border: none;
      padding: 15px 25px;
      cursor: pointer;
      @media (max-width: 479px) {
        font-size: 16px;
      }
    }
  }
  .search__container {
    display: flex;
    flex-direction: row;
    width: 60%;
    @media (max-width: 767px) {
      width: 100%;
    }

    .search__input {
      width: 100%;
      padding: 20px 24px;
      border: none;
      background-color: transparent;
      transition: transform 250ms ease-in-out;
      font-size: 14px;
      line-height: 18px;
      outline: none;
      color: var(--secondaryColor);

      backface-visibility: hidden;
    }
    .search_icon {
      display: flex;
      width: 70px;
      background-color: var(--purpleColor);
      justify-content: center;
      align-items: center;
      border-radius: 69.3284px;

      svg {
        color: #fff;
        font-size: 30px;
      }
    }
    .search__input::placeholder {
      color: rgba(87, 87, 86, 0.8) !important;
      text-transform: lowercase;
      letter-spacing: 1.5px;
    }
  }
`
